import React from 'react';
import { styled } from '../../../styled-system/jsx';
import { SystemProperties } from '../../../styled-system/types/style-props';

export const Spinner: React.FC<SystemProperties> = (prop) => {
  return (
    <styled.span
      minWidth={'24px'}
      minHeight={'24px'} // size指定をもう少しいい感じにしたい
      // width={'32px'}
      // height={'32px'}
      aspectRatio={1}
      border={'4px solid #FFF'}
      borderBottomColor={'#22CC22'}
      borderRadius={'full'}
      display={'inline-block'}
      boxSizing={'border-box'}
      animation={'spin'}
      {...prop}
    />
  );
};
