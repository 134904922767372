import { Text } from '@chakra-ui/react';
import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>エラー{String(error)}</h1>
      <Text>{error instanceof Error ? error.message : String(error)}</Text>
    </div>
  );
};
