// import { ChakraProvider } from '@chakra-ui/provider';
// import { ChakraProvider, extendBaseTheme } from '@chakra-ui/react';
// import { Button as ButtonTheme } from '@chakra-ui/theme/components';
import {
  QueryClient,
  QueryClientProvider,
  QueryClientProviderProps,
} from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
// import React from 'react';
import * as React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
// import { ReactQueryDevtools } from 'react-query/devtools';

// const theme = extendBaseTheme({
//   components: {
//     Button: ButtonTheme,
//   },
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
export const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const queryClient = React.useMemo(() => new QueryClient(), []);
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ChakraProvider theme={theme}> */}
      {
        children as QueryClientProviderProps['children'] /* なぜかエラーになるのでキャスト */
      }
      <ToastContainer
        position="top-center"
        autoClose={5000}
        bodyStyle={{
          // minWidth: 'max-content',
          // width: '1800px',
          width: '100vw',
          // minWidth: { base: '100%', lg: '100vw' },
          maxWidth: '100vw',
          flexWrap: 'wrap',
          display: 'flex',
        }}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        theme="colored"
      />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* </ChakraProvider> */}
    </QueryClientProvider>
  );
};
