import React from 'react';
import { createBrowserRouter, Route } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';
import { Page404 } from './pages/404';
// import BookingFormPage from './pages/BookingFormPage';

import { Spinner } from './components/ui/Spinner';
import { SentryRoutes } from './sentry';
const BookingFormPage = React.lazy(() => import('./pages/BookingFormPage'));
const BookingDetailPage = React.lazy(() => import('./pages/BookingDetailPage'));
const PochicoLiffPage = React.lazy(() => import('./pages/PochicoLiffPage'));
const BookingFormPreviewPage = React.lazy(
  () => import('./pages/BookingFormPreviewPage')
);

const suspense = (children: React.ReactNode) => {
  return <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>;
};
const Root = () => {
  return (
    // <Routes>
    <SentryRoutes>
      <Route
        path="/booking/:providerAccountId"
        element={suspense(<PochicoLiffPage />)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/booking-form-preview"
        element={suspense(<BookingFormPreviewPage />)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/booking-form"
        element={suspense(<BookingFormPage />)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/booking-detail"
        element={suspense(<BookingDetailPage />)}
        errorElement={<ErrorPage />}
      />
      <Route path="*" element={<Page404 />} />
    </SentryRoutes>
    // </Routes>
  );
};

export const router = createBrowserRouter([
  {
    path: '*',
    Component: Root,
    errorElement: <ErrorPage />,
  },
]);
